@charset "UTF-8";
@import url(base.css);
@import url(common.css);
/*---------------------------------
Page CSS 2018.03
CSS 担当者名 Document 2018.03
Last Update 2018.03 担当者名
---------------------------------*/
/*
  404
  -------------------------------------*/
/*
  ホーム
  -------------------------------------*/
#Page.pageIndex #Main .contBox {
  min-width: 1000px;
  padding: 80px 0 100px; }
  #Page.pageIndex #Main .contBox .lowerBox {
    max-width: 1280px;
    width: 100%;
    padding: 0 30px;
    margin: 0 auto; }
  #Page.pageIndex #Main .contBox .linkBtn {
    margin: 38px auto 0; }

#Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock {
  height: 378px;
  font-size: 0; }
  #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock + .topSeBlock {
    margin-top: 30px; }
  #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock:first-of-type:before, #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock:nth-of-type(3):before {
    content: "";
    display: inline-block;
    vertical-align: top;
    width: 50%;
    height: 378px;
    background: url(../img/index_img01.jpg) no-repeat center center;
    background-size: cover; }
  #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock:nth-of-type(2):after {
    content: "";
    display: inline-block;
    vertical-align: top;
    width: 50%;
    height: 378px;
    background: url(../img/index_img02.jpg) no-repeat center center;
    background-size: cover; }
  #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock:nth-of-type(3):before {
    background: url(../img/index_img03.jpg) no-repeat center center;
    background-size: cover; }
  #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock > div {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    height: 378px;
    margin: 0 0 0 auto;
    border: 1px solid #72d0e1;
    border-left: none;
    text-align: center;
    padding-top: 60px;
    position: relative; }
    #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock > div:before {
      content: "";
      display: block;
      width: 10px;
      height: 378px;
      background: #fff;
      position: absolute;
      top: -1px;
      left: 0; }
    #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock > div dl dt {
      font-size: 26px;
      font-weight: 700;
      color: #000;
      letter-spacing: 2px; }
      #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock > div dl dt span {
        color: #72d0e2;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-style: italic;
        font-size: 60px;
        display: block;
        margin-bottom: 24px;
        letter-spacing: 0; }
    #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock > div dl dd {
      margin: 12px 0 22px;
      font-size: 16px;
      color: #666;
      line-height: 28px;
      text-align: center; }
    #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock > div .moreLink {
      margin: 0 auto; }
  #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock:nth-of-type(2) > div {
    border: 1px solid #72d0e1;
    border-right: none; }
    #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock:nth-of-type(2) > div:before {
      display: none; }
    #Page.pageIndex #Main #ContBox01 .lowerBox div.topSeBlock:nth-of-type(2) > div:after {
      content: "";
      display: block;
      width: 10px;
      height: 378px;
      background: #fff;
      position: absolute;
      top: -1px;
      right: 0; }

#Page.pageIndex #Main #ContBox02 {
  background: #f3f8f9; }
  #Page.pageIndex #Main #ContBox02 .lowerBox {
    overflow: hidden; }
    #Page.pageIndex #Main #ContBox02 .lowerBox > div {
      width: 50%;
      float: left;
      padding-right: 10px; }
      #Page.pageIndex #Main #ContBox02 .lowerBox > div + div {
        padding-left: 10px;
        padding-right: 0; }
      #Page.pageIndex #Main #ContBox02 .lowerBox > div #Gmap01, #Page.pageIndex #Main #ContBox02 .lowerBox > div #Gmap02 {
        width: 100%;
        height: 226px;
        border: 8px solid #fff; }
      #Page.pageIndex #Main #ContBox02 .lowerBox > div dl {
        color: #000;
        font-size: 16px;
        line-height: 28px;
        margin-top: 12px; }
      #Page.pageIndex #Main #ContBox02 .lowerBox > div .linkArrow {
        margin-top: 5px; }

#Page.pageIndex #Main #ContBox03 dl {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-top: 1px solid #eeeeee;
  padding: 20px 30px 19px 0;
  background: url(../img/arrow03.png) no-repeat center right;
  background-size: 9px auto; }
  #Page.pageIndex #Main #ContBox03 dl:last-of-type {
    border-bottom: 1px solid #eeeeee; }
  #Page.pageIndex #Main #ContBox03 dl dd {
    display: table-cell;
    vertical-align: middle; }
    #Page.pageIndex #Main #ContBox03 dl dd.date {
      width: 140px;
      line-height: 30px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      border-right: 1px dotted #999;
      font-size: 14px;
      color: #999;
      letter-spacing: 2px; }
    #Page.pageIndex #Main #ContBox03 dl dd.category {
      padding: 0 18px 0 50px;
      width: 168px; }
      #Page.pageIndex #Main #ContBox03 dl dd.category span {
        display: block;
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: #fff;
        border-radius: 2px;
        text-align: center;
        letter-spacing: 2px; }
      #Page.pageIndex #Main #ContBox03 dl dd.category.topics span {
        background: #e3ac32; }
      #Page.pageIndex #Main #ContBox03 dl dd.category.performance span {
        background: #65c293; }
  #Page.pageIndex #Main #ContBox03 dl dt {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    #Page.pageIndex #Main #ContBox03 dl dt a {
      font-size: 16px;
      color: #000;
      text-decoration: none;
      display: inline-block; }
      #Page.pageIndex #Main #ContBox03 dl dt a:hover {
        color: #06a0be; }

/*
  事業案内 共通
  -------------------------------------*/
#PageService #Main #ContBox01 {
  margin-top: 60px;
  margin-bottom: 50px; }
  #PageService #Main #ContBox01 .textB {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    text-align: center;
    letter-spacing: 1px;
    line-height: 36px; }
  #PageService #Main #ContBox01.seRowerBox .contSubBox {
    margin-bottom: 80px; }
    #PageService #Main #ContBox01.seRowerBox .contSubBox .h3 {
      padding-top: 20px; }
    #PageService #Main #ContBox01.seRowerBox .contSubBox figure {
      width: 630px;
      float: left;
      margin-right: 58px; }
    #PageService #Main #ContBox01.seRowerBox .contSubBox div {
      width: 312px;
      float: right; }
    #PageService #Main #ContBox01.seRowerBox .contSubBox:nth-of-type(2n) figure {
      float: right;
      margin-left: 58px;
      margin-right: 0; }
    #PageService #Main #ContBox01.seRowerBox .contSubBox:nth-of-type(2n) div {
      float: left; }
    #PageService #Main #ContBox01.seRowerBox .contSubBox:after {
      content: "";
      display: block;
      visibility: hidden;
      clear: both;
      height: 0; }
  #PageService #Main #ContBox01.seRowerBox #ContSubBox01 {
    margin-top: 70px; }

/*
  事業案内
  -------------------------------------*/
#PageService.pageIndex #Main #ContBox01 .textB {
  line-height: 28px; }

#PageService.pageIndex #Main #ContBox01 ol {
  list-style: none; }

#PageService.pageIndex #Main #ContBox01 #ContSubBox01 {
  margin-top: 75px; }
  #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol {
    font-size: 0; }
    #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol li {
      width: 314px;
      display: inline-block;
      vertical-align: top;
      padding-top: 205px; }
      #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol li + li {
        margin-left: 29px; }
      #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol li:first-child {
        background: url(../img/contents/service_img01.jpg) no-repeat top left;
        background-size: 314px auto; }
      #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol li:nth-child(2) {
        background: url(../img/contents/service_img02.jpg) no-repeat top left;
        background-size: 314px auto; }
      #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol li:nth-child(3) {
        background: url(../img/contents/service_img03.jpg) no-repeat top left;
        background-size: 314px auto; }
        #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol li:nth-child(3) > div dl dt {
          letter-spacing: 0; }
        #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol li:nth-child(3) > div dl dd {
          letter-spacing: -1px; }
      #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol li > div {
        border: 1px solid #72d0e1;
        border-top: none;
        padding: 24px 30px 0;
        height: 315px; }
        #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol li > div dl dt {
          font-size: 22px;
          font-weight: 700;
          color: #000;
          letter-spacing: 1px;
          text-align: center; }
          #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol li > div dl dt span {
            color: #72d0e2;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            font-style: italic;
            font-size: 40px;
            display: block;
            margin-bottom: 25px;
            letter-spacing: 0; }
        #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol li > div dl dd {
          margin: 12px 0 22px;
          font-size: 16px;
          color: #666;
          line-height: 28px; }
        #PageService.pageIndex #Main #ContBox01 #ContSubBox01 ol li > div .moreLink {
          margin: 0 auto; }

#PageService.pageIndex #Main #ContBox01 #ContSubBox02 {
  margin-top: 75px; }
  #PageService.pageIndex #Main #ContBox01 #ContSubBox02 .textB {
    text-align: left;
    margin-bottom: 25px; }
  #PageService.pageIndex #Main #ContBox01 #ContSubBox02 dl {
    border-top: 1px solid #72d0e1;
    padding: 24px 0 53px;
    font-size: 0; }
    #PageService.pageIndex #Main #ContBox01 #ContSubBox02 dl dt, #PageService.pageIndex #Main #ContBox01 #ContSubBox02 dl dd {
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
      line-height: 28px; }
    #PageService.pageIndex #Main #ContBox01 #ContSubBox02 dl dt {
      width: 200px;
      margin-right: 50px;
      color: #000;
      font-weight: 700;
      position: relative;
      padding-left: 20px; }
      #PageService.pageIndex #Main #ContBox01 #ContSubBox02 dl dt:before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #72d0e2;
        position: absolute;
        top: 9px;
        left: 0; }
    #PageService.pageIndex #Main #ContBox01 #ContSubBox02 dl dd {
      width: 750px;
      color: #666; }
      #PageService.pageIndex #Main #ContBox01 #ContSubBox02 dl dd ol li + li {
        margin-top: 15px; }

/*
  3PL物流業務代行
  -------------------------------------*/
#PageService.page3plIndex #Main #ContBox01 {
  margin-bottom: 130px; }

/*
  大規模3PL物流代行
  -------------------------------------*/
#PageService.pageLargeIndex #Main #ContBox01 {
  margin-bottom: 140px; }
  #PageService.pageLargeIndex #Main #ContBox01 .textB {
    letter-spacing: 2px; }

/*
  カスタマーサービス代行
  -------------------------------------*/
#PageService.pageCustomerIndex #Main #ContBox01 {
  margin-bottom: 140px; }

/*
  会社案内
  -------------------------------------*/
#PageCompany.pageIndex #Main {
  margin-bottom: 130px; }
  #PageCompany.pageIndex #Main .contBox {
    margin-top: 90px; }
  #PageCompany.pageIndex #Main #ContBox01 {
    margin-top: 58px;
    overflow: hidden; }
    #PageCompany.pageIndex #Main #ContBox01 > div {
      width: 630px;
      float: left; }
      #PageCompany.pageIndex #Main #ContBox01 > div + div {
        width: 310px;
        float: right;
        margin-top: 5px; }
        #PageCompany.pageIndex #Main #ContBox01 > div + div dl {
          font-size: 14px;
          line-height: 24px;
          font-weight: 700; }
          #PageCompany.pageIndex #Main #ContBox01 > div + div dl:first-of-type {
            margin: 15px 0;
            text-align: center; }
            #PageCompany.pageIndex #Main #ContBox01 > div + div dl:first-of-type dd {
              letter-spacing: 1px;
              text-align: center; }
          #PageCompany.pageIndex #Main #ContBox01 > div + div dl.profile {
            border-top: 1px solid #72d0e1; }
            #PageCompany.pageIndex #Main #ContBox01 > div + div dl.profile dt {
              margin: 13px 0 7px;
              padding-left: 20px;
              position: relative; }
              #PageCompany.pageIndex #Main #ContBox01 > div + div dl.profile dt:before {
                content: "";
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #72d0e2;
                position: absolute;
                top: 7px;
                left: 0; }
            #PageCompany.pageIndex #Main #ContBox01 > div + div dl.profile dd {
              font-weight: 500;
              color: #666; }
  #PageCompany.pageIndex #Main #ContBox02 dl {
    display: table;
    width: 100%;
    border: 1px solid #eee;
    border-top: none;
    padding: 20px 0; }
    #PageCompany.pageIndex #Main #ContBox02 dl:first-of-type {
      border-top: 1px solid #eee; }
    #PageCompany.pageIndex #Main #ContBox02 dl dt, #PageCompany.pageIndex #Main #ContBox02 dl dd {
      display: table-cell;
      vertical-align: top;
      font-size: 16px;
      line-height: 28px;
      padding-left: 30px; }
    #PageCompany.pageIndex #Main #ContBox02 dl dt {
      width: 190px;
      border-right: 1px dotted #999; }
  #PageCompany.pageIndex #Main #ContBox03 .contSubBox {
    margin: 60px 0; }
  #PageCompany.pageIndex #Main #ContBox03 #Gmap01, #PageCompany.pageIndex #Main #ContBox03 #Gmap02 {
    width: 100%;
    height: 350px;
    margin-top: 30px; }
  #PageCompany.pageIndex #Main #ContBox03 .access {
    margin-top: 13px; }
    #PageCompany.pageIndex #Main #ContBox03 .access ul li {
      display: inline-block;
      vertical-align: middle;
      line-height: 28px; }
      #PageCompany.pageIndex #Main #ContBox03 .access ul li:first-child {
        font-size: 16px;
        margin-right: 14px;
        letter-spacing: 0.5px; }
  #PageCompany.pageIndex #Main #ContBox03 .companyImg {
    margin-top: 32px;
    font-size: 0; }
    #PageCompany.pageIndex #Main #ContBox03 .companyImg li {
      width: 314px;
      display: inline-block;
      vertical-align: top;
      margin-right: 29px; }
      #PageCompany.pageIndex #Main #ContBox03 .companyImg li:nth-child(3n) {
        margin-right: 0; }
      #PageCompany.pageIndex #Main #ContBox03 .companyImg li:nth-child(n+4) {
        margin-top: 29px; }

/*
  ニュース 共通
  -------------------------------------*/
#PageNews #Main {
  float: right;
  width: 760px;
  margin-top: 55px;
  margin-bottom: 120px; }
  #PageNews #Main .listBlock, #PageNews #Main .entryBlock {
    padding-bottom: 80px;
    margin-bottom: 80px;
    border-bottom: 1px solid #eee; }
    #PageNews #Main .listBlock ul, #PageNews #Main .entryBlock ul {
      margin-bottom: 15px;
      font-size: 0; }
      #PageNews #Main .listBlock ul li, #PageNews #Main .entryBlock ul li {
        display: inline-block;
        vertical-align: middle; }
        #PageNews #Main .listBlock ul li.date, #PageNews #Main .entryBlock ul li.date {
          color: #999;
          font-size: 14px;
          margin-right: 17px;
          font-family: 'Lato', sans-serif;
          font-weight: 400;
          letter-spacing: 2px; }
        #PageNews #Main .listBlock ul li.category, #PageNews #Main .entryBlock ul li.category {
          width: 100px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          color: #fff;
          border-radius: 2px;
          text-align: center;
          letter-spacing: 2px;
          margin-right: 10px; }
          #PageNews #Main .listBlock ul li.category.topics, #PageNews #Main .entryBlock ul li.category.topics {
            background: #e3ac32; }
          #PageNews #Main .listBlock ul li.category.performance, #PageNews #Main .entryBlock ul li.category.performance {
            background: #65c293; }
    #PageNews #Main .listBlock h3 a, #PageNews #Main .listBlock .h3 a, #PageNews #Main .entryBlock h3 a, #PageNews #Main .entryBlock .h3 a {
      color: #000;
      text-decoration: none; }
      #PageNews #Main .listBlock h3 a:hover, #PageNews #Main .listBlock .h3 a:hover, #PageNews #Main .entryBlock h3 a:hover, #PageNews #Main .entryBlock .h3 a:hover {
        text-decoration: underline; }
  #PageNews #Main .entryBlock {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0; }
  #PageNews #Main nav.pageMove ul {
    width: 542px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    font-size: 0; }
    #PageNews #Main nav.pageMove ul li {
      width: 40px;
      height: 40px;
      display: inline-block;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      border-radius: 8px; }
      #PageNews #Main nav.pageMove ul li + li + li {
        margin-left: 20px; }
      #PageNews #Main nav.pageMove ul li.current {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: #ddd;
        color: #fff; }
        #PageNews #Main nav.pageMove ul li.current + li {
          margin-left: 20px; }
      #PageNews #Main nav.pageMove ul li a {
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        color: #06a0be;
        border: 1px solid #72d0e1;
        border-radius: 8px;
        text-decoration: none; }
        #PageNews #Main nav.pageMove ul li a:hover {
          background: #25b0c8;
          border: none;
          color: #fff; }
      #PageNews #Main nav.pageMove ul li.prev, #PageNews #Main nav.pageMove ul li.next {
        position: absolute;
        top: 0;
        width: 90px;
        height: 40px;
        line-height: 40px;
        margin-left: 0;
        font-size: 12px;
        font-family: 'Lato', sans-serif;
        font-weight: 400; }
        #PageNews #Main nav.pageMove ul li.prev a, #PageNews #Main nav.pageMove ul li.next a {
          color: #fff;
          background: url(../img/contents/pagemove_bg.png) no-repeat top left;
          background-size: 90px auto;
          border: none; }
          #PageNews #Main nav.pageMove ul li.prev a:hover, #PageNews #Main nav.pageMove ul li.next a:hover {
            opacity: 0.7; }
      #PageNews #Main nav.pageMove ul li.prev {
        left: 0; }
        #PageNews #Main nav.pageMove ul li.prev a:before {
          content: "";
          display: inline-block;
          width: 7px;
          height: 40px;
          margin-right: 10px;
          background: url(../img/arrow06.png) no-repeat center left;
          background-size: 7px auto;
          vertical-align: top; }
      #PageNews #Main nav.pageMove ul li.next {
        right: 0; }
        #PageNews #Main nav.pageMove ul li.next a:after {
          content: "";
          display: inline-block;
          width: 7px;
          height: 40px;
          margin-left: 10px;
          background: url(../img/arrow02.png) no-repeat center right;
          background-size: 7px auto;
          vertical-align: top; }

/*
  ニュース
  -------------------------------------*/
/*
  ニュース（カテゴリ）
  -------------------------------------*/
#PageNews.pageCategory #Main #ContBox01 h3.h4 {
  margin-bottom: 80px; }

/*
  ニュース（詳細）
  -------------------------------------*/
/*
  お問い合わせ
  -------------------------------------*/
#PageContact.pageIndex #Main #ContBox01,
#PageContact.pageConfirm #Main #ContBox01,
#PageContact.pageThanks #Main #ContBox01 {
  margin-top: 64px; }
  #PageContact.pageIndex #Main #ContBox01 > dl,
  #PageContact.pageConfirm #Main #ContBox01 > dl,
  #PageContact.pageThanks #Main #ContBox01 > dl {
    width: 440px;
    margin: 0 auto;
    background: #f3f8f9;
    padding: 30px 0;
    text-align: center; }
    #PageContact.pageIndex #Main #ContBox01 > dl dt,
    #PageContact.pageConfirm #Main #ContBox01 > dl dt,
    #PageContact.pageThanks #Main #ContBox01 > dl dt {
      font-size: 16px;
      font-weight: 700; }
    #PageContact.pageIndex #Main #ContBox01 > dl dd,
    #PageContact.pageConfirm #Main #ContBox01 > dl dd,
    #PageContact.pageThanks #Main #ContBox01 > dl dd {
      font-size: 14px;
      color: #666;
      letter-spacing: 0.5px;
      text-align: center; }
      #PageContact.pageIndex #Main #ContBox01 > dl dd.tel, #PageContact.pageIndex #Main #ContBox01 > dl dd .tel,
      #PageContact.pageConfirm #Main #ContBox01 > dl dd.tel,
      #PageContact.pageConfirm #Main #ContBox01 > dl dd .tel,
      #PageContact.pageThanks #Main #ContBox01 > dl dd.tel,
      #PageContact.pageThanks #Main #ContBox01 > dl dd .tel {
        font-size: 39px;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 2px;
        margin: 14px 0 17px;
        color: #000;
        line-height: 44px; }
        #PageContact.pageIndex #Main #ContBox01 > dl dd.tel:before, #PageContact.pageIndex #Main #ContBox01 > dl dd .tel:before,
        #PageContact.pageConfirm #Main #ContBox01 > dl dd.tel:before,
        #PageContact.pageConfirm #Main #ContBox01 > dl dd .tel:before,
        #PageContact.pageThanks #Main #ContBox01 > dl dd.tel:before,
        #PageContact.pageThanks #Main #ContBox01 > dl dd .tel:before {
          content: "";
          display: inline-block;
          vertical-align: top;
          margin-right: 8px;
          width: 32px;
          height: 44px;
          background: url(../img/tel_icon01.png) no-repeat center left;
          background-size: 32px auto; }
  #PageContact.pageIndex #Main #ContBox01 h3.h4,
  #PageContact.pageConfirm #Main #ContBox01 h3.h4,
  #PageContact.pageThanks #Main #ContBox01 h3.h4 {
    font-size: 26px;
    padding-bottom: 28px;
    margin-bottom: 24px; }
  #PageContact.pageIndex #Main #ContBox01 #ContSubBox01,
  #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01,
  #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 {
    margin-top: 70px; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 p,
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 p,
    #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 p {
      color: #666; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form,
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form,
    #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form {
      margin-top: 33px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl,
      #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl {
        width: 100%;
        display: table;
        border-bottom: 1px solid #eee;
        padding: 20px 0 15px;
        color: #000; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl:first-of-type,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl:first-of-type,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl:first-of-type {
          border-top: 1px solid #eee; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl:first-of-type, #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl:nth-of-type(2),
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl:first-of-type,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl:nth-of-type(2),
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl:first-of-type,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl:nth-of-type(2) {
          padding-bottom: 8px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dt, #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dt,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dt,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd {
          display: table-cell;
          vertical-align: top; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dt,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dt,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dt {
          width: 180px;
          font-size: 16px;
          padding-top: 8px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type {
          width: 100px;
          text-align: center;
          padding-top: 5px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child {
          width: 720px;
          font-size: 16px;
          line-height: 28px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"],
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"],
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"],
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"],
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"],
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"],
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"],
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"],
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"],
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea {
            width: 100%;
            height: 34px;
            border: 1px solid #b8b8b8;
            padding-left: 10px;
            border-radius: 4px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"]:focus,
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"]:focus,
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"]:focus,
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea:focus,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"]:focus,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"]:focus,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"]:focus,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea:focus,
            #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"]:focus,
            #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"]:focus,
            #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"]:focus,
            #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea:focus {
              border: 1px solid #25b0c8; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text3,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text3,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text3 {
            width: 350px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text4, #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text5,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text4,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text5,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text4,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text5 {
            width: 300px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text6,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text6,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text6 {
            width: 140px;
            margin-left: 4px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text7,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text7,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text7 {
            margin-top: 15px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text8,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text8,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text8 {
            width: 200px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text9, #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text10,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text9,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text10,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text9,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text10 {
            width: 450px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea {
            height: 184px;
            padding: 5px 10px;
            resize: none;
            line-height: 22px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span {
            width: 25px;
            height: 25px;
            margin-right: 5px;
            background: transparent url(../img/contents/radio02.png) no-repeat scroll left center;
            background-size: 25px 25px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span.checked,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span.checked,
            #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span.checked {
              background: transparent url(../img/contents/radio01.png) no-repeat scroll left center;
              background-size: 25px 25px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span input,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span input,
            #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span input {
              width: 25px;
              height: 25px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child label,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child label,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:last-child label {
            display: inline-block;
            margin-bottom: 15px;
            margin-right: 30px;
            line-height: 25px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form .caution,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form .caution,
      #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form .caution {
        margin: 48px 0 20px;
        background: #f6f6f6;
        padding: 32px 30px 24px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form .caution h4,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form .caution h4,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form .caution h4 {
          font-size: 16px;
          border: none;
          padding: 0;
          margin-bottom: 23px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form .caution ul,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form .caution ul,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form .caution ul {
          margin: 0; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form .caution ul li,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form .caution ul li,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form .caution ul li {
            font-size: 14px;
            line-height: 24px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form .caution ul li + li,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form .caution ul li + li,
            #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form .caution ul li + li {
              margin-top: 10px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul.checkBox,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul.checkBox,
      #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul.checkBox {
        text-align: center;
        margin: 0; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul.checkBox li,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul.checkBox li,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul.checkBox li {
          display: inline-block;
          vertical-align: middle; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul.checkBox li + li,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul.checkBox li + li,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul.checkBox li + li {
            margin-left: 27px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul.checkBox li .checker span,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul.checkBox li .checker span,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul.checkBox li .checker span {
            display: inline-block;
            width: 25px;
            height: 25px;
            background: url(../img/contents/check02.png) no-repeat center center;
            background-size: 25px 25px;
            vertical-align: middle; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul.checkBox li .checker span.checked,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul.checkBox li .checker span.checked,
            #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul.checkBox li .checker span.checked {
              background: url(../img/contents/check01.png) no-repeat center center;
              background-size: 25px 25px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul.checkBox li .checker span input,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul.checkBox li .checker span input,
            #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul.checkBox li .checker span input {
              width: 25px;
              height: 25px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul.checkBox li label,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul.checkBox li label,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul.checkBox li label {
            font-size: 16px;
            vertical-align: middle;
            margin-left: 8px;
            line-height: 25px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span, #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul.checkBox li span,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul.checkBox li span,
      #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span,
      #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul.checkBox li span {
        display: inline-block;
        width: 40px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: #fff;
        text-align: center;
        border-radius: 2px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span.required, #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul.checkBox li span.required,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span.required,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul.checkBox li span.required,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span.required,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul.checkBox li span.required {
          background: #ff912b; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul,
      #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul {
        position: relative;
        width: 100%;
        margin: 40px 0 0; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li.reset,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li.reset,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul li.reset {
          position: absolute;
          top: 0;
          left: 0;
          line-height: 60px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"],
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"],
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"] {
            opacity: 1;
            color: #06a0be;
            font-size: 12px;
            text-decoration: underline; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"]:hover,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"]:hover,
            #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"]:hover {
              text-decoration: none; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li #image-btn,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li #image-btn,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul li #image-btn {
          display: block;
          width: 300px;
          height: 60px;
          margin: 0px auto;
          border-radius: 7px;
          background: #06a0be url(../img/arrow02.png) no-repeat center right 35px;
          background-size: 7px auto;
          opacity: 1;
          color: #fff;
          font-size: 16px;
          line-height: 60px;
          letter-spacing: 2px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li #image-btn:hover,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li #image-btn:hover,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 form ul li #image-btn:hover {
            background: #fff url(../img/arrow01.png) no-repeat center right 35px;
            background-size: 7px auto;
            border: 1px solid #72d0e1;
            color: #06a0be; }
  #PageContact.pageIndex #Main #ContBox01 #ContSubBox02,
  #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02,
  #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 {
    margin-top: 100px;
    padding-top: 80px;
    background: #f6f6f6; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 h3,
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 h3,
    #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 h3 {
      margin-bottom: 29px;
      line-height: 1;
      font-size: 20px;
      letter-spacing: 0; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 h3:after,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 h3:after,
      #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 h3:after {
        display: none; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock,
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock,
    #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock {
      border-top: 1px solid #999;
      font-size: 0;
      padding: 23px 0 53px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock h4,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock h4,
      #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock h4 {
        width: 250px;
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        border: none;
        line-height: 28px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock > div,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock > div,
      #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock > div {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        line-height: 28px;
        color: #666; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock > div > ol > li,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock > div > ol > li,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock > div > ol > li {
          text-indent: -1.3em;
          padding-left: 1.3em;
          letter-spacing: 0.5px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock > div > ol > li + li,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock > div > ol > li + li,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock > div > ol > li + li {
            margin-top: 20px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock > div p.sign,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock > div p.sign,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock > div p.sign {
          font-size: 14px;
          line-height: 24px;
          margin-top: 22px;
          text-align: right; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl + dl,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl + dl,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl + dl {
          margin-top: 20px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table th,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table th,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table th {
          width: 50%;
          height: 30px;
          line-height: 30px;
          background: #535353;
          font-size: 14px;
          color: #fff;
          font-weight: 500;
          padding-left: 10px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table th + th,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table th + th,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table th + th {
            border-left: 1px solid #eee; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table td,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table td,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table td {
          font-size: 14px;
          line-height: 24px;
          background: #fff;
          padding: 12px 10px 12px;
          text-align: justify;
          text-justify: inter-ideograph; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table td + td,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table td + td,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table td + td {
            border-left: 1px solid #eee; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table tr + tr + tr td,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table tr + tr + tr td,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl table tr + tr + tr td {
          border-top: 1px solid #eee; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl ol,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl ol,
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl ol {
          list-style: none;
          padding-left: 23px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl ol li dl dd,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl ol li dl dd,
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox02 .privacyBlock > div > dl ol li dl dd {
            padding-left: 2.5em; }

/*
  お問い合わせ（確認）
  -------------------------------------*/
#PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form#form1 dl {
  padding: 20px 0; }

#PageContact.pageConfirm #Footer,
#PageContact.pageThanks #Footer {
  margin-top: 100px;
  border-top: 1px solid #eee; }

/*
  お問い合わせ（完了）
  -------------------------------------*/
#PageContact.pageThanks #Main #ContBox01 > dl {
  margin-top: 34px;
  width: 100%;
  text-align: left;
  padding: 24px 0 30px; }
  #PageContact.pageThanks #Main #ContBox01 > dl dt {
    width: 340px;
    display: inline-block;
    vertical-align: middle;
    padding-left: 40px;
    padding-top: 7px; }
  #PageContact.pageThanks #Main #ContBox01 > dl dd {
    display: inline-block;
    vertical-align: middle;
    text-align: center; }
    #PageContact.pageThanks #Main #ContBox01 > dl dd .tel {
      margin-top: 0; }
      #PageContact.pageThanks #Main #ContBox01 > dl dd .tel + li {
        text-align: center; }

#PageContact.pageThanks #Main #ContBox01 .linkBtn {
  margin: 40px auto 0; }
