@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.03
CSS 担当者名 Document 2018.03
Last Update 2018.03 担当者名
---------------------------------*/
  /*
  404
  -------------------------------------*/
  #Page404 #Main{
    #ContBox01{
      #ContSubBox01{
      }
      #ContSubBox02{
      }
    }
  }
  /*
  ホーム
  -------------------------------------*/
  #Page.pageIndex #Main{
    .contBox{
      min-width:1000px;
      padding:80px 0 100px;
      .lowerBox{
        max-width:1280px;
        width:100%;
        padding:0 30px;
        margin:0 auto;
      }
      .linkBtn{
        margin:38px auto 0;
      }
    }
    #ContBox01{
      .lowerBox{
        div.topSeBlock{
          height:378px;
          font-size:0;
          &+.topSeBlock{
            margin-top:30px;
          }
          &:first-of-type,&:nth-of-type(3){
            &:before{
              content:"";
              display: inline-block;
              vertical-align: top;
              width:50%;
              height:378px;
              background:url(../img/index_img01.jpg) no-repeat center center;
              background-size:cover;
            }
          }
          &:nth-of-type(2){
            &:after{
              content:"";
              display: inline-block;
              vertical-align: top;
              width:50%;
              height:378px;
              background:url(../img/index_img02.jpg) no-repeat center center;
              background-size:cover;
            }
          }
          &:nth-of-type(3){
            &:before{
              background:url(../img/index_img03.jpg) no-repeat center center;
              background-size:cover;
            }
          }
          >div{
            display: inline-block;
            vertical-align: top;
            width:50%;
            height:378px;
            margin:0 0 0 auto;
            border:1px solid #72d0e1;
            border-left: none;
            text-align:center;
            padding-top:60px;
            position:relative;
            &:before{
              content:"";
              display:block;
              width:10px;
              height:378px;
              background:#fff;
              position:absolute;
              top:-1px;
              left:0;
            }
            dl{
              dt{
                font-size:26px;
                font-weight:700;
                color:#000;
                letter-spacing:2px;
                span{
                  color:#72d0e2;
                  font-family: 'Lato', sans-serif;
                  font-weight: 400;
                  font-style:italic;
                  font-size:60px;
                  display:block;
                  margin-bottom:24px;
                  letter-spacing:0;
                }
              }
              dd{
                margin:12px 0 22px;
                font-size:16px;
                color:#666;
                line-height:28px;
                text-align:center;
              }
            }
            .moreLink{
              margin:0 auto;
            }
          }
          &:nth-of-type(2){
            >div{
              border:1px solid #72d0e1;
              border-right: none;
              &:before{
                display:none;
              }
              &:after{
                content:"";
                display:block;
                width:10px;
                height:378px;
                background:#fff;
                position:absolute;
                top:-1px;
                right:0;
              }
            }
          }
        }
      }
      #ContSubBox01{
      }
      #ContSubBox02{
      }
    }
    #ContBox02{
      background:#f3f8f9;
      .lowerBox{
        overflow: hidden;
        >div{
          width:50%;
          float:left;
          padding-right: 10px;
          &+div{
            padding-left:10px;
            padding-right:0;
          }
          #Gmap01,#Gmap02{
            width:100%;
            height:226px;
            border:8px solid #fff;
          }
          dl{
            color:#000;
            font-size:16px;
            line-height:28px;
            margin-top:12px;
          }
          .linkArrow{
            margin-top:5px;
          }
        }
      }
    }
    #ContBox03{
      dl{
        display:table;
        width:100%;
        table-layout: fixed;
        border-top:1px solid #eeeeee;
        padding:20px 30px 19px 0;
        background:url(../img/arrow03.png) no-repeat center right;
        background-size:9px auto;
        &:last-of-type{
          border-bottom:1px solid #eeeeee;
        }
        dd{
          display:table-cell;
          vertical-align:middle;
          &.date{
            width:140px;
            line-height:30px;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border-right:1px dotted #999;
            font-size:14px;
            color:#999;
            letter-spacing:2px;
          }
          &.category{
            padding:0 18px 0 50px;
            width:168px;
            span{
              display:block;
              width:100%;
              height:20px;
              line-height:20px;
              font-size:12px;
              color:#fff;
              border-radius:2px;
              text-align:center;
              letter-spacing:2px;
            }
            &.topics{
              span{
                background:#e3ac32;
              }
            }
            &.performance{
              span{
                background:#65c293;
              }
            }
          }
        }
        dt{
          display:table-cell;
          vertical-align:middle;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          a{
            font-size:16px;
            color:#000;
            text-decoration:none;
            display:inline-block;
            &:hover{
              color:#06a0be;
            }
          }
        }
      }
    }
  }

  /*
  事業案内 共通
  -------------------------------------*/
  #PageService #Main{
    #ContBox01{
      margin-top:60px;
      margin-bottom:50px;
      .textB{
        font-size:20px;
        font-weight:700;
        color:#000;
        text-align:center;
        letter-spacing:1px;
        line-height:36px;
      }
      &.seRowerBox{
        .contSubBox{
          .h3{
            padding-top:20px;
          }
          //padding-left:688px;
          margin-bottom:80px;
          //min-height:390px;
          figure{
            width:630px;
            float:left;
            margin-right:58px;
          }
          div{
            width:312px;
            float:right;
          }
          &:nth-of-type(2n){
            //padding-left:0;
            //padding-right:688px;
            figure{
              float:right;
              margin-left:58px;
              margin-right:0;
            }
            div{
              float:left;
            }
          }
          &:after{
            content: "";
            display: block;
            visibility: hidden;
            clear: both;
            height: 0;
          }
        }
        #ContSubBox01{
          margin-top:70px;
        }
      }
    }
  }
  /*
  事業案内
  -------------------------------------*/
  #PageService.pageIndex #Main{
    #ContBox01{
      .textB{
        line-height:28px;
      }
      ol{
        list-style: none;
      }
      #ContSubBox01{
        margin-top:75px;
        ol{
          font-size:0;
          li{
            width:314px;
            display:inline-block;
            vertical-align:top;
            padding-top:205px;
            &+li{
              margin-left:29px;
            }
            &:first-child{
              background:url(../img/contents/service_img01.jpg) no-repeat top left;
              background-size:314px auto;
            }
            &:nth-child(2){
              background:url(../img/contents/service_img02.jpg) no-repeat top left;
              background-size:314px auto;
            }
            &:nth-child(3){
              background:url(../img/contents/service_img03.jpg) no-repeat top left;
              background-size:314px auto;
              >div{
                dl{
                  dt{
                    letter-spacing:0;
                  }
                  dd{
                    letter-spacing:-1px;
                  }
                }
              }
            }
            >div{
              border:1px solid #72d0e1;
              border-top:none;
              padding:24px 30px 0;
              height:315px;
              dl{
                dt{
                  font-size:22px;
                  font-weight:700;
                  color:#000;
                  letter-spacing:1px;
                  text-align:center;
                  span{
                    color:#72d0e2;
                    font-family: 'Lato', sans-serif;
                    font-weight: 400;
                    font-style:italic;
                    font-size:40px;
                    display:block;
                    margin-bottom:25px;
                    letter-spacing:0;
                  }
                }
                dd{
                  margin:12px 0 22px;
                  font-size:16px;
                  color:#666;
                  line-height:28px;
                }
              }
              .moreLink{
                margin:0 auto;
              }
            }
          }
        }
      }
      #ContSubBox02{
        margin-top:75px;
        .textB{
          text-align:left;
          margin-bottom:25px;
        }
        dl{
          border-top:1px solid #72d0e1;
          padding:24px 0 53px;
          font-size:0;
          dt,dd{
            display:inline-block;
            vertical-align:top;
            font-size:16px;
            line-height:28px;
          }
          dt{
            width:200px;
            margin-right:50px;
            color:#000;
            font-weight:700;
            position:relative;
            padding-left:20px;
            &:before{
              content:"";
              display:inline-block;
              width:10px;
              height:10px;
              border-radius:50%;
              background:#72d0e2;
              position:absolute;
              top:9px;
              left:0;
            }
          }
          dd{
            width:750px;
            color:#666;
            ol{
              li+li{
                margin-top:15px;
              }
            }
          }
        }
      }
    }
  }

  /*
  3PL物流業務代行
  -------------------------------------*/
  #PageService.page3plIndex #Main{
    #ContBox01{
      margin-bottom:130px;
    }
  }

  /*
  大規模3PL物流代行
  -------------------------------------*/
  #PageService.pageLargeIndex #Main{
    #ContBox01{
      margin-bottom:140px;
      .textB{
        letter-spacing:2px;
      }
    }
  }

  /*
  カスタマーサービス代行
  -------------------------------------*/
  #PageService.pageCustomerIndex #Main{
    #ContBox01{
      margin-bottom:140px;
    }
  }

  /*
  会社案内
  -------------------------------------*/
  #PageCompany.pageIndex #Main{
    margin-bottom:130px;
    .contBox{
      margin-top:90px;
    }
    #ContBox01{
      margin-top:58px;
      overflow:hidden;
      >div{
        width:630px;
        float:left;
        &+div{
          width:310px;
          float:right;
          margin-top:5px;
          dl{
            font-size:14px;
            line-height:24px;
            font-weight:700;
            &:first-of-type{
              margin:15px 0;
              text-align:center;
              dd{
                letter-spacing:1px;
                text-align:center;
              }
            }
            &.profile{
              border-top:1px solid #72d0e1;
              dt{
                margin:13px 0 7px;
                padding-left:20px;
                position:relative;
                &:before{
                  content:"";
                  display:inline-block;
                  width:10px;
                  height:10px;
                  border-radius:50%;
                  background:#72d0e2;
                  position:absolute;
                  top:7px;
                  left:0;
                }
              }
              dd{
                font-weight:500;
                color:#666;
              }
            }
          }
        }
      }
    }
    #ContBox02{
      dl{
        display:table;
        width:100%;
        border:1px solid #eee;
        border-top:none;
        padding:20px 0;
        &:first-of-type{
          border-top:1px solid #eee;
        }
        dt,dd{
          display:table-cell;
          vertical-align:top;
          font-size:16px;
          line-height:28px;
          padding-left:30px;
        }
        dt{
          width:190px;
          border-right:1px dotted #999;
        }
      }
    }
    #ContBox03{
      .contSubBox{
        margin:60px 0;
      }
      #Gmap01,#Gmap02{
        width:100%;
        height:350px;
        margin-top:30px;
      }
      .access{
        margin-top:13px;
        ul{
          li{
            display:inline-block;
            vertical-align:middle;
            line-height:28px;
            &:first-child{
              font-size:16px;
              margin-right:14px;
              letter-spacing:0.5px;
            }
          }
        }
      }
      .companyImg{
        margin-top:32px;
        font-size:0;
        li{
          width:314px;
          display:inline-block;
          vertical-align:top;
          margin-right:29px;
          &:nth-child(3n){
            margin-right:0;
          }
          &:nth-child(n+4){
            margin-top:29px;
          }
        }
      }
    }
  }

  /*
  ニュース 共通
  -------------------------------------*/
  #PageNews #Main{
    float:right;
    width:760px;
    margin-top:55px;
    margin-bottom:120px;
    .listBlock,.entryBlock{
      padding-bottom:80px;
      margin-bottom:80px;
      border-bottom:1px solid #eee;
      ul{
        margin-bottom:15px;
        font-size:0;
        li{
          display:inline-block;
          vertical-align:middle;
          &.date{
            color:#999;
            font-size:14px;
            margin-right:17px;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            letter-spacing:2px;
          }
          &.category{
            width:100px;
            height:20px;
            line-height:20px;
            font-size:12px;
            color:#fff;
            border-radius:2px;
            text-align:center;
            letter-spacing:2px;
            margin-right:10px;
            &.topics{
              background:#e3ac32;
            }
            &.performance{
              background:#65c293;
            }
          }
        }
      }
      h3,.h3{
        a{
          color:#000;
          text-decoration:none;
          &:hover{
            text-decoration:underline;
          }
        }
      }
    }
    .entryBlock{
      border:none;
      padding-bottom:0;
      margin-bottom:0;
    }
    nav.pageMove{
      ul{
        width:542px;
        margin:0 auto;
        position: relative;
        text-align:center;
        font-size:0;
        li{
          width:40px;
          height:40px;
          display:inline-block;
          line-height: 40px;
          text-align:center;
          font-size:14px;
          border-radius:8px;
          &+li+li{
              margin-left:20px;
          }
          &.current{
            display:inline-block;
            width:40px;
            height:40px;
            line-height:40px;
            background:#ddd;
            color:#fff;
            &+li{
                margin-left:20px;
            }
          }
          a{
            display:block;
            width:100%;
            height:100%;
            text-align:center;
            color:#06a0be;
            border:1px solid #72d0e1;
            border-radius:8px;
            text-decoration:none;
            &:hover{
              background:#25b0c8;
              border:none;
              color:#fff;
            }
          }
          &.prev,&.next{
            position:absolute;
            top:0;
            width:90px;
            height:40px;
            line-height:40px;
            margin-left:0;
            font-size:12px;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            a{
              color:#fff;
              background:url(../img/contents/pagemove_bg.png) no-repeat top left;
              background-size:90px auto;
              border:none;
              &:hover{
                opacity:0.7;
              }
            }
          }
          &.prev{
            left:0;
            a{
              &:before{
                content:"";
                display:inline-block;
                width:7px;
                height:40px;
                margin-right:10px;
                background:url(../img/arrow06.png) no-repeat center left;
                background-size: 7px auto;
                vertical-align: top;
              }
            }
          }
          &.next{
            right:0;
            a{
              &:after{
                content:"";
                display:inline-block;
                width:7px;
                height:40px;
                margin-left:10px;
                background:url(../img/arrow02.png) no-repeat center right;
                background-size: 7px auto;
                vertical-align: top;
              }
            }
          }
        }
      }
    }
  }

  /*
  ニュース
  -------------------------------------*/
  #PageNews.pageIndex #Main{
    #ContBox01{
    }
  }

  /*
  ニュース（カテゴリ）
  -------------------------------------*/
  #PageNews.pageCategory #Main{
    #ContBox01{
      h3.h4{
        margin-bottom:80px;
      }
    }
  }

  /*
  ニュース（詳細）
  -------------------------------------*/
  #PageNews.pageEntry #Main{
    #ContBox01{
      #ContSubBox01{
      }
      #ContSubBox02{
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact.pageIndex #Main,
  #PageContact.pageConfirm #Main,
  #PageContact.pageThanks #Main{
    #ContBox01{
      margin-top:64px;
      >dl{
        width:440px;
        margin:0 auto;
        background:#f3f8f9;
        padding:30px 0;
        text-align:center;
        dt{
          font-size:16px;
          font-weight:700;
        }
        dd{
          font-size:14px;
          color:#666;
          letter-spacing:0.5px;
          text-align:center;
          &.tel,.tel{
            font-size:39px;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            letter-spacing:2px;
            margin:14px 0 17px;
            color:#000;
            line-height:44px;
            &:before{
              content:"";
              display:inline-block;
              vertical-align:top;
              margin-right:8px;
              width:32px;
              height:44px;
              background:url(../img/tel_icon01.png) no-repeat center left;
              background-size:32px auto;
            }
          }
        }
      }
      h3.h4{
        font-size:26px;
        padding-bottom:28px;
        margin-bottom:24px;
      }
      #ContSubBox01{
        margin-top:70px;
        p{
          color:#666;
        }
        form{
          margin-top:33px;
          dl{
            width:100%;
            display: table;
            border-bottom:1px solid #eee;
            padding:20px 0 15px;
            color:#000;
            &:first-of-type{
              border-top:1px solid #eee;
            }
            &:first-of-type,&:nth-of-type(2){
              padding-bottom:8px;
            }
            dt,dd{
              display:table-cell;
              vertical-align:top;
            }
            dt{
              width:180px;
              font-size:16px;
              padding-top:8px;
            }
            dd{
              &:first-of-type{
                width:100px;
                text-align:center;
                padding-top:5px;
              }
              &:last-child{
                width:720px;
                font-size:16px;
                line-height:28px;
                input[type="text"],
                input[type="email"],
                input[type="tel"],
                textarea{
                  width:100%;
                  height:34px;
                  border:1px solid #b8b8b8;
                  padding-left:10px;
                  border-radius:4px;
                  &:focus{
                    border:1px solid #25b0c8;
                  }
                }
                #text3{
                  width:350px;
                }
                #text4,#text5{
                  width:300px;
                }
                #text6{
                  width:140px;
                  margin-left:4px;
                }
                #text7{
                  margin-top:15px;
                }
                #text8{
                  width:200px;
                }
                #text9,#text10{
                  width:450px;
                }
                textarea{
                  height:184px;
                  padding:5px 10px;
                  resize: none;
                  line-height:22px;
                }
                .radio{
                  span{
                    width: 25px;
                    height: 25px;
                    margin-right: 5px;
                    background: transparent url(../img/contents/radio02.png) no-repeat scroll left center;
                    background-size:25px 25px;
                    &.checked{
                      background: transparent url(../img/contents/radio01.png) no-repeat scroll left center;
                      background-size:25px 25px;
                    }
                    input{
                      width:25px;
                      height:25px;
                    }
                  }
                }
                label{
                  display:inline-block;
                  margin-bottom:15px;
                  margin-right: 30px;
                  line-height: 25px;
                }
              }
            }
          }
          .caution{
            margin:48px 0 20px;
            background:#f6f6f6;
            padding:32px 30px 24px;
            h4{
              font-size:16px;
              border:none;
              padding:0;
              margin-bottom:23px;
            }
            ul{
              margin:0;
              li{
                font-size:14px;
                line-height:24px;
                &+li{
                  margin-top:10px;
                }
              }
            }
          }
          ul.checkBox{
            text-align:center;
            margin:0;
            li{
              display:inline-block;
              vertical-align:middle;
              &+li{
                margin-left:27px;
              }
              .checker{
                span{
                  display: inline-block;
                  width: 25px;
                  height: 25px;
                  background: url(../img/contents/check02.png) no-repeat center center;
                  background-size: 25px 25px;
                  vertical-align: middle;
                  &.checked{
                    background: url(../img/contents/check01.png) no-repeat center center;
                    background-size: 25px 25px;
                  }
                  input{
                    width:25px;
                    height:25px;
                  }
                }
              }
              label{
                font-size:16px;
                vertical-align:middle;
                margin-left: 8px;
                line-height: 25px;
              }
            }
          }
          dl dd:first-of-type span,ul.checkBox li span{
            display:inline-block;
            width:40px;
            height:20px;
            line-height:20px;
            font-size: 12px;
            color:#fff;
            text-align: center;
            border-radius:2px;
            &.required{
              background:#ff912b;
            }
          }
          ul{
            position: relative;
            width:100%;
            margin:40px 0 0;
            li{
              &.reset{
                position:absolute;
                top:0;
                left:0;
                line-height:60px;
                input[type="button"]{
                  opacity: 1;
                  color: #06a0be;
                  font-size: 12px;
                  text-decoration: underline;
                  &:hover{
                    text-decoration:none;
                  }
                }
              }
              #image-btn{
                display: block;
                width: 300px;
                height: 60px;
                margin: 0px auto;
                border-radius: 7px;
                background: #06a0be url(../img/arrow02.png) no-repeat center right 35px;
                background-size: 7px auto;
                opacity: 1;
                color: #fff;
                font-size: 16px;
                line-height: 60px;
                letter-spacing:2px;
                &:hover{
                  background: #fff url(../img/arrow01.png) no-repeat center right 35px;
                  background-size: 7px auto;
                  border:1px solid #72d0e1;
                  color:#06a0be;
                }
              }
            }
          }
        }
      }
      #ContSubBox02{
        margin-top:100px;
        padding-top:80px;
        background:#f6f6f6;
        h3{
          margin-bottom:29px;
          line-height:1;
          font-size:20px;letter-spacing:0;
          &:after{
            display:none;
          }
        }
        .privacyBlock{
          border-top:1px solid #999;
          font-size:0;
          padding:23px 0 53px;
          h4{
            width:250px;
            display:inline-block;
            vertical-align:top;
            font-size:16px;
            border:none;
            line-height:28px;
          }
          >div{
            width:750px;
            display:inline-block;
            vertical-align:top;
            font-size:16px;
            line-height:28px;
            color:#666;
            >ol{
              >li{
                text-indent:-1.3em;
                padding-left:1.3em;
                letter-spacing: 0.5px;
                &+li{
                  margin-top:20px;
                }
              }
            }
            p.sign{
              font-size:14px;
              line-height:24px;
              margin-top:22px;
              text-align:right;
            }
            >dl{
              &+dl{
                margin-top:20px;
              }
              table{
                th{
                  width:50%;
                  height:30px;
                  line-height:30px;
                  background:#535353;
                  font-size:14px;
                  color:#fff;
                  font-weight:500;
                  padding-left:10px;
                  &+th{
                    border-left:1px solid #eee;
                  }
                }
                td{
                  font-size:14px;
                  line-height:24px;
                  background:#fff;
                  padding:12px 10px 12px;
                  text-align:justify;
                  text-justify: inter-ideograph;
                  &+td{
                    border-left:1px solid #eee;
                  }
                }
                tr+tr+tr{
                  td{
                    border-top:1px solid #eee;
                  }
                }
              }
              ol{
                list-style:none;
                padding-left:23px;
                li{
                  dl{
                    dd{
                      padding-left:2.5em;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  お問い合わせ（確認）
  -------------------------------------*/
  #PageContact.pageConfirm #Main{
    #ContBox01{
      #ContSubBox01{
        form#form1{
          dl{
            padding:20px 0;
          }
        }
      }
    }
  }

  #PageContact.pageConfirm,
  #PageContact.pageThanks{
    #Footer{
      margin-top:100px;
      border-top:1px solid #eee;
    }
  }

  /*
  お問い合わせ（完了）
  -------------------------------------*/
  #PageContact.pageThanks #Main{
    #ContBox01{
      >dl{
        margin-top:34px;
        width:100%;
        text-align:left;
        padding:24px 0 30px;
        dt{
          width:340px;
          display:inline-block;
          vertical-align:middle;
          padding-left:40px;
          padding-top:7px;
        }
        dd{
          display:inline-block;
          vertical-align:middle;
          text-align:center;
          .tel{
            margin-top:0;
            &+li{
              text-align:center;
            }
          }
        }
      }
      .linkBtn{
        margin:40px auto 0;
      }
      #ContSubBox01{
      }
      #ContSubBox02{
      }
    }
  }


